.palyamu-kategoriak {
	margin-top: 15px;
	padding-top: 15px;
	border-top: 1px solid @vilagosSzurke;
	display: none;

	&.show {
		display: block;
	}

	.kategoria {
		float: left;
		width: 237px;
		height: 55px;
		padding-right: 10px;

		a {
			color: @betuSzin;
			font-size: @baseFontSize * 1.8;
			line-height: 1.1em;

			&:hover, &.aktiv {
				color: @trabantKek;
			}
		}
	}
}

.palyamu_lista {
	padding: 22px 0;
	margin: 15px 0 0 0;
	border-top: 1px solid @vilagosSzurke;
}

.palyamu-container {
	margin: 0 0 22px;
}

.palyamu {
	background-color: #fff;
	padding: 8px 10px 10px;

	a {
		color: @betuSzin;
		display: block;
		height: 311px;
		position: relative;

		&:hover, &.aktiv {
			color: @trabantKek;

			.szerzo {
				color: #666;
			}
		}
	}

	.kep {
		margin: 0 auto 12px auto;

	}

	.szavazatok {
		float: right;
		background: url("@{images-url}szavazatszam_bg.png") no-repeat right center transparent;
		font-size: 16px;
		color: @kek2015;
		font-style: normal;
		padding: 0 25px 0 0;
		margin: 0;
		width: auto;
		height: 26px;
		line-height: 26px;
	}

	.nev {

		font-size: 16px;
		line-height: 19px;
		color: @kek2015;
	}

	.szerzo {
		font-style: italic;
		font-size: 14px;
		font-weight: 300;
		color: #666;
	}

	.kategoria {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		font-size: 12px;
		font-weight: 700;
		font-style: italic;
		border-top: 1px solid #f3f3f3;
		color: @kek2015;
		padding: 10px 0 0;
	}

	.palyamu_cimszoveg {
//		width: 276px;
	}
}

#benyujtott-palyamuvek {
	.kategorianev {
		padding: 0 0 0 30px;
	}
}

#benyujtott-palyamu {
	position: relative;

	.infosav{
		height: auto !important;
		.display-tablecell{
			height: auto !important;
			padding-top: 2rem;
		}
	}

	.row:first-child {
		margin-bottom: 30px;
	}

	.padder {
		padding: 0 0 30px;
	}

	.kategorianev {
		padding: 0 0 0 30px;
	}

	.image-holder {
		padding: 20px;
	}

	.nagykep {
		float: right;
		margin-left: 25px;

		&+p {
			text-align: justify;
		}
	}

	.szavazas {
		/*position: absolute;*/
		/*left: 0;*/
		/*bottom: 30px;*/
	}

	.szavazatok {
		margin: 10px 10px 10px 0;
		&+a {
			line-height: 42px;
		}
	}

	p {
		text-align: justify;

		&.elerhetoseg {
			text-align: left;
		}
	}

	.ikonos{
		width: 100%;
		//background-image: url(/media/images/toplista_bg.png);
		//background-size: cover;
		//background-position: 2%;
		h2{
			text-align: center;
			width: 100%;
		}
	}
}

#palyazatok td, #palyazatok th{
	padding: 5px;
}
#palyazatok th {
	text-align: left;
}
#palyazatok {
	width: 100%;
}
#vegosszeg{
	font-size: 24px;
}

.seosiker {
	padding: 20px;
	h2 {
		text-align: center;
		font-size: 18px;
		font-weight: 700;
		margin: 10px 0 50px;
	}
}

.seosiker_graf {
	margin-bottom: 0;
	dt {
		text-align: left;

		span {
			height: 100%;
		}
	}

	li, dt, dd {
		margin: 0 0 55px;
		width: 50%;
		height: 88px;

		&:last-child {
			margin: 0;
		}
	}

	dd {
		div{
			width: 100px;
			height: 88px;
			margin: 10px auto;
			background-image: url(/media/images/donut-bg.png);
			background-size: 88px 88px;
			background-repeat: no-repeat;
			background-position: 6px 0;
		}
	}



	a {
		color: #fff;
		font-weight: 300;
		font-size: 25px;
		display: block;
		position: relative;

		span {
			position: absolute;
			width: 100%;
			height: 100%;
			display: block;
			text-align: center;
			line-height: 88px;
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {

	#benyujtott-palyamu {
		.row:first-child {
			margin-left: -15px;
			margin-right: 0;
		}
	}

	#benyujtott-palyamu .szavazok span.szavazatok-szama {
		padding: 0 0 0 95px;
		background: url("@{images-url}szavazok_bg.png") no-repeat left 11px center transparent;
	}

	.seosiker {
		h2 {
			margin-bottom: 20px;
		}
	}

	.seosiker_graf {
		dt {
			margin-bottom: 0;
			width: auto;

		}

		li, dt, dd {
			margin: 0;
			width: auto;
			height: auto;
		}

		dd {
			margin-bottom: 20px;
		}

		a {
			font-size: 25px;
		}
	}
}

div.ar{
	font-size:24px;
}
#vegosszeg_csillag{
	display:none;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.sikeres-palyazat-ikonok{
	margin: 60px 0px;
	img{
		max-height: 100px;
	}
	h2{
		font-size: 15px;
		color: #3E6CB2;
		margin-top: 30px;
	}
	.col-sm-4{
		margin-bottom: 30px;
	}

}

.palyazat-kerdoiv{
	margin: 50px 0px;
	label{
		display: inline-block;

	}
	.form-group>div{
		// margin-bottom: 5px;
	}
	.form-group{
		margin-left: 10px;
	}
}
