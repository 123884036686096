/*
 * HTML5 Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html,
button,
input,
select,
textarea {
//    color: #222;
}

body {
    font-size: 1em;
    line-height: 1.4;
}

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

/*
 * Remove the gap between images and the bottom of their containers: h5bp.com/i/440
 */

img {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
    resize: vertical;
}

/* ==========================================================================
   Chrome Frame prompt
   ========================================================================== */

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
	background-color: #fff;
	font-size: 14px;
	font-family: 'museo_sans500';
}

dt {
	font-weight: normal;
	font-family: 'museo_sans700';
}

.bg-kek {
	background-color: #2B79BC;
	color: #fff !important;

	a, a:active, a:visited, a:hover{
		color: #fff;
	}

	h2 {
		color: #fff !important;
	}
}

.bg-feher {
	background-color: #fff;
}

.nopadding {
	padding-top: 0;
}

.padding-0 {
	padding: 0 !important;
}

.display-table {
	display: table;
	width: 100%;
}

.display-tablecell {
	display: table-cell;
	height: 100%;
	vertical-align: middle;
}

.header-fooldal {
	padding-bottom: 10px;

	.container {
		padding-top: 5px;
		text-align: center;
	}

	h1,
	h2 {
		color: @vilagosKek2015;
	}

	h1 {
		margin-top: -40px;
		font-size: 35px;
	}

	h2 {
		font-size: 20px;
		margin-bottom: 20px;
	}
}

@media (max-width: 768px) {
	.header-fooldal {
		padding-bottom: 30px;
	}
}

.btn.btn-default.tovabb {
	font-size: 0;
	text-indent: -8000px;
	width: 40px;
	height: 40px;
	padding: 0;
	background: url("@{images-url}tovabb_bg.png") no-repeat center center transparent;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;

	&:after {
		width: 130%;
		height: 130%;
		top: -6px;
		left: -6px;
		border: 1px solid #4954a0;
	}
}

#ajanlas-blokk {
	padding: 40px 0 0;

	h2 {
		font-size: 25px;
		color: @kek2015;
		font-family: 'museo_sans300';
		line-height: 30px;
		margin: 10px 0 40px;
		strong{
			font-size: 35px;
		}
	}
}

.ajanlo g *{
	fill: #7097CD;
}
.ajanlo svg{
	max-width: 135px;
}

@media (min-width: @screen-sm-min) {
	#ajanlas{
		margin-top: 80px;
	}
}


#ajanlas{
	margin-bottom: 30px;
}

.ajanlas {
	input[type="text"] {
		width: 100%;
	}
}

section {
	padding: 30px 0;

	&.ikonos {
		padding: 75px 0 30px;
		background: url("@{images-url}ikonos_section_bg.png") no-repeat center 0 transparent;
	}

	h2 {
		text-transform: uppercase;
		color: @kek2015;
		font-size: 25px;
		font-family: 'museo_sans700';
		margin: 0 0 25px;
	}

	&.toplista {
		background: url("@{images-url}toplista_bg.png") no-repeat center bottom @sotetkek2015;
		padding-top: 77px;
		padding-bottom: 150px;
	}

	.miniblock {
		padding: 120px 0 70px;

		&.seosiker-block {
			background: url("@{images-url}seotop_bg.png") no-repeat center 0 transparent;
		}

		&.kozonseg-block {
			background: url("@{images-url}kozonseg_bg.png") no-repeat center 0 transparent;
		}

		&.facebook-block {
			background: url("@{images-url}fblike_bg.png") no-repeat center 0 transparent;
		}

		h2 {
			color: @vilagoskek2015;
			font-size: 15px;
		}

		.btn.borderes:after {
			width: 115.6%;
		}
	}

	.like {
		background: url("@{images-url}like_bg.png") no-repeat center bottom transparent;
		padding: 15px 0 70px;
		color: @vilagosKek2015;
		font-size: 45px;
		margin-bottom: 52px;
		text-align: center;
	}
}

.toplista h1{
	margin: 50px 30px 52px;
	font-size: 26px;
	text-align: center;
	color: @vilagosKek2015;
}
.toplista h3{
	font-size: 26px !important;
	text-align: center;
	color: @vilagosKek2015 !important;
	font-weight: bold;
}

.toplista p{
	color: @vilagosKek2015;
	text-align: center;
	font-size: 14px;
	font-style: italic;
}

ul.toplista {
	margin-bottom: 35px;

	span {
		display: inline-block;
		width: 80px;
		text-align: right;
		font-family: 'museo_sans700';
		font-size: 24px;
		color: @vilagosKek2015;
		margin: 0 20px 0 0;
	}

	a {
		font-size: 15px;
		font-family: 'museo_sans300_italic';
		color: #92b0d9;

		&:hover {
			color: @vilagosKek2015;
		}
	}
}

.palyamuvek-container {
	padding: 0 0 25px;

	.item {
		height: auto;
		margin: 0 0 -1px;
		padding: 0;
		width: 12.5%;
		padding-bottom : 8%;
		overflow: hidden;
		position: relative;

		.item-inner {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		a {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			.transition(all 0.15s linear);

			&:after {
				.transition(all 0.15s linear);
				content: ' ';
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url("@{images-url}palyamunka_hover.png") repeat 0 0 transparent;
				zoom: 1;
				filter: alpha(opacity=0);
				opacity: 0;
			}

			img {
				width: 100%;
				height: 100%;
			}

			&:hover {
				&:after {
					zoom: 1;
					filter: alpha(opacity=100);
					opacity: 1;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.palyamuvek-container {
		.item {
			width: 50%;
			padding-bottom : 31%;
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.palyamuvek-container {
		.item {
			width: 25%;
			padding-bottom : 15%;
		}
	}
}

.zsuri-container {
	padding: 0 0 30px;

	.zsuritag {
		text-align: center;
		font-size: 13px;
		color: @szurke2015;
		line-height: 15px;
		font-family: 'museo_sans300';
		height: 225px;

		img {
			.border-radius(90px);
			border: 10px solid #fff;
		}

		.nev {
			color: @kek2015;
			text-transform: uppercase;
			font-size: 15px;
			font-family: 'museo_sans700';
		}

		p {
			margin: 0;
		}
	}
}

.piktogramok {
	position: fixed;
	right: 17px;
	top: 135px;
	z-index: 1;

	li {
		margin-bottom: 10px;
	}

	a {
		width: 50px;
		height: 50px;
		padding: 5px;
		background-color: @vilagoskek2015;
		.border-radius(50px);
		display: block;

		svg {
			fill: @sotetkek2015;
		}

		&:hover {
			svg {
				fill: #C3FFFF;
			}
		}
	}
}

.hirek-container {
	padding: 0 0 40px;

	.hir-doboz {
		height: 324px;
		overflow: hidden;
	}



	.hirhatter {
		padding: 15px;
		background-color: #fff;
		position: relative;
		height: 324px;

		&.padding-vert {
			padding-left: 0;
			padding-right: 0;
		}

		.btn {
			position: absolute;
			bottom: 10px;
		}
	}

	&.hirek-oldalt {
		.hir-doboz {
			height: auto;
			margin: 0 0 20px;
		}

		.hirhatter {
			height: auto;
			padding-bottom: 55px;
		}
	}

	.kiemelt {
		.hirhatter {
			.btn {
				left: 52%;
			}
		}
	}

	.text {
		font-size: 13px;
		font-family: 'museo_sans300';
	}
}

@media (max-width: 768px) {

	.hirek-container {
		.hirhatter,
		.hir-doboz {
			height: auto;
		}

		.hirhatter {
			.btn {
				position: static;
				margin-top: 20px;
			}
		}

		.hir-doboz:first-child {
			.hirhatter {
				.btn {
					margin-left: 15px;
				}
			}
		}
	}

}

@media (max-width: 991px) {
	.hirek-container .hir-doboz {
		margin-bottom: 20px;
	}
}

.masonry-cols {
	@item-margin: @grid-gutter-width / 2;

	.set-grid-size,
	.masonry-elem {
		float: left;
		width: 25%;
		height: auto;
		overflow: hidden;
		margin: 0 0 10px;

		&.kiemelt {
			width: 50%;
		}
	}

	.hir-doboz {
		padding: 0 10px 0 0;

		.hirhatter {
			min-height: 324px;

		}

		.img-container {
			width: 100%;
			height: 100%;
		}
	}
}

#regisztracio,
#palyazat-benyujtasa {
	.alcim {
		font-weight: normal;
		margin: 20px 0;
		font-family: 'museo_sans700';
		font-size: 20px;
	}

	h3 {
		font-size: 16px;
	}
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Image replacement
 */

.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    /* IE 6/7 fallback */
    *text-indent: -9999px;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */

.hidden {
    display: none !important;
    visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screenreaders, but maintain layout
 */

.invisible {
    visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */

.clearfix {
    *zoom: 1;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   Theses examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
    /* Style adjustments for viewports that meet the condition */
}

@media print
       (-o-min-device-pixel-ratio: 5/4),
       (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {
    /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links for images, or javascript/internal links
     */

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

@media (max-width: 991px) {
	.ui-dialog {
		width: auto !important;
		z-index: 10000;
	}
}

.mt-30{
	margin-top: 30px;
}

.pdf_melleklet{
	display: none;
}