header {
	height: 219px;
	background: url("@{images-url}header_title_bg.png") center 0 no-repeat;
	color: @white;

	.navbar-default {

		.navbar-inner {
			position: relative;
			.transition(all 0.15s linear);
		}

		&.alter {
			.navbar-inner {
				&::after {
					.opacity(100);
				}
			}
		}
	}

	.logo {
		margin-right: 36px;
	}

	.menu-cim {
		padding-top: 100px;

		h2 {

			line-height: 120px;
			font-size: 35px;
			text-transform: uppercase;
			font-family: 'museo_sans300';
		}
	}

	.fooldal & {
		height: auto;
		background: url("@{images-url}bg2.jpg") 50% 40% no-repeat;
	}

	.informacio {
		margin: 30px 0 17px;
		color: #5292c8;
		text-transform: uppercase;
		font-size: 12px;

		span {
			font-size: 50px;
			display: block;
			color: @vilagosKek2015;
			padding-bottom: 10px;
			margin-bottom: 10px;

			&:after {
				content: ' ';
				background: #4a56a1;
				position: absolute;
				bottom: 35px;
				left: 41%;
				height: 1px;
				width: 17%;
			}
		}
	}

	nav {
//		position: absolute;
//		left: 250px;
//		top: @navTop;

		li {
//			display: block;
//			float: left;
		}

		&>ul>li {
			font-family: @headerFont;
			font-family: 'museo_sans100';
			margin-left: 35px;

			&:first-child {
				margin-left: 0;
			}

			a {
				font-size: 35px;
				line-height: @baseFontSize * 6;
				color: @fomenupont;
				font-style: normal;
				/* cursor: default; */
			}

			ul {
//				position: absolute;
//				left: 0;
//				top: 100%;
//				display: none;

				li {
//					margin-left: 12px;

					&:first-child{
//						margin-left: 0;
					}
				}

				a {
					font-family: @arial;
					color: @white;
					cursor: pointer;
					font-size: @baseFontSize * 1.4;
					line-height: 1.4em;

					&:visited, &:active {
						color: @white;
					}

					&:hover {
						color: @fomenupont;
					}
				}
			}
		}
	}

	.imgs {
		position: absolute;
		display: block;
		top: @navTop - 15;

		&.evhonlapja_logo {
			left: 0;
		}

		&.marketingszovetseg {
			left: 115px;
			padding-top: 30px;
		}
	}

	.holnapod {
		position: absolute;
		left: 330px;
		top: 155px;
	}

	.regisztracio {
		position: absolute;
//		height: 68px;
		z-index: 1000;
		top: -65px;
		left: 0;
		background: @sotetSzurke;
		width: 100%;
		.transition(all 0.5s ease);

		&.nyitva {
			top: 0;
		}

		span {
			color: @szurke;
			display: inline-block;
			font-size: @baseFontSize * 2;
			line-height: @baseFontSize * 3.5;
			letter-spacing: -1px;
		}

		.container {
			padding: 15px 0;
		}

		.cim {
			position: absolute;
			right: 0;
			top: 100%;
			background: @sotetSzurke;
			font-size: @baseFontSize * 1.4;
			font-family: @calibri;
			line-height: 1em;
			margin: 0;
			.border-radius(0 0 10px 10px);
			padding: 5px 20px 10px;
			cursor: pointer;
			color: @white;
			letter-spacing: 0;
		}

		.btn_anchor {
			margin-left: 10px;
		}

		form {
			input[type=text],
			input[type=password] {
				background: @szurke;
				color: @white;
			}
		}

		.bezar {
			cursor: pointer;
			margin-right: 10px;

			&:before {
				display: block;
				color: @white;
				content: 'x';
				font-size: @baseFontSize * 1.8;
				line-height: 35px;
			}
		}

		.loggedin {
			a {
				display: inline-block;
				font-size: @baseFontSize * 2.4;
				line-height: @baseFontSize * 3.5;
				letter-spacing: -1px;
			}

			.logout {
				color: @szurke;

				&:hover {
					color: @trabantKek;
				}
			}

			.nev {
				color: @trabantKek;
			}
		}

		.padder {
			padding: 0 0 10px;
		}
	}
}

@media (min-width: 768px) {
	header {
		.navbar-default {

			.navbar-inner {

				&::after {
					content: "";
					background: url(/media/images/header_bg.png);
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					position: absolute;
					z-index: -1;
					.transition(all 0.15s linear);
					.opacity(0);
				}
			}

			&.alter {
				.navbar-inner {
					&::after {
						.opacity(100);
					}
				}
			}
		}
	}


	.regisztracio{
		.form-group{
			margin-bottom: 0;
		}
	}
}

@media (min-width: 768px) and (max-width: 900px) {
	.navbar-nav>li>a {
		font-size: 14px;
	}

	.nav>li>a {
		padding: 10px;
	}
}

@media (max-width: 768px) {
	header {
		height: 109px;
		.logo {
			margin-left: 10px;
		}

		.navbar-collapse.collapse.in {
			background-color: #102481;
		}

		.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse, .pre-scrollable {
			max-height: none;
		}
	}
}

@media (max-width: 900px) {
	header .logo {
		margin-right: 10px;
	}
}

@media (max-width: 992px) {
	header {
		.regisztracio {
			height: 250px;
			position: fixed;
			left: 0;
			bottom: -245px;
			top: auto;

			&.nyitva {
				bottom: 0;
				top: auto;
			}

			.cim {
				left:0;
				right:0;
				margin-left:auto;
				margin-right:auto;
				top: -29px;
				.border-radius(10px 10px 0 0);
				padding: 10px 20px 5px;
				width: 250px;
				text-align: center;
			}
		}
	}

}

@media (max-width: 767px) {
	header {
		.regisztracio {
			height: 250px;
			bottom: -245px;
		}
	}
}
