/*body {
	background: @white;
	color: @betuSzin;
	font-family: @calibri;
	font-size: @paragraphFontSize;
	line-height: @baseLineHeight;
	min-width: @containerWidth;
}*/

a:focus,
a:active {
	outline: 0 none !important;
	-moz-outline: 0 none !important;
}

::-moz-focus-inner {
	border: 0;
}

h1,h2,h3,h4,h5,h6 {
	font-family: 'museo_sans100';
//	font-family: @headerFont;
	margin: 0 0 .5em 0;
}

a, a:hover, a:visited, a:active, a:focus {
	text-decoration: none;
	color: @trabantKek;
}

ul, ol {
	list-style-position: outside;
	margin: 0;
	padding: 0;

	li {
		margin: 0;
		padding: 0;
	}
}

ul {
	list-style: none;
}

ol {
	list-style: decimal;
}

.container {
//	width: @containerWidth;
	margin: 0 auto;
	position: relative;
}

.holnapod {
	font: 12px/16px normal @arial;
	color: @white;
	text-transform: uppercase;
}

section {
//	padding: 30px 0;
}

hr {
	margin: 10px 0;
	height: 0;
	border-top: 1px solid @vilagosSzurke;
}

.cim {
	color: @kek2015;
	font-size: @baseFontSize * 3;
//	font-family: @headerFont;
	font-family: 'museo_sans700';
	font-style: normal;
	line-height: 1.2;
	margin-bottom: 15px;
	text-transform: none;

	a {
		color: @kek2015;

		&:hover {
			color: lighten(@kek2015, 20%);
		}
	}
}

.kiemelt .hirhatter .cim {
	font-size: 25px;
}

.hirhatter .cim {
	font-size: 22px;
}

.datum {
	font-size: 13px;
	padding: 0 0 10px;
	margin: 0 0 12px;
	border-bottom: 1px solid #eeeeee;
}

.alcim {
	color: @betuSzin;
	font-size: @baseFontSize * 1.4;
	font-family: 'museo_sans300';
	margin-bottom: 10px;
	line-height: 1.2;

	.cim + & {
		margin-top: -5px;
	}
}

.cikkcim {
	color: @trabantKek;
	font-size: @baseFontSize * 2.5;
	line-height: 1em;
	margin-bottom: 10px;

	a:hover {
		color: darken(@trabantKek, 20%);
	}
}

.datum {
	font-style: italic;
}

.kekplusz:before {
	@size:	@baseFontSize * 3.6;

	background: @trabantKek;
	content: '+';
	color: @white;
	display: block;
	float: left;
	font-size: @size;
//	font-family: @headerFont;
	font-family: 'museo_sans100';
	height: @size;
	line-height: @size * 0.9;
	margin: 12px 12px 12px 0;
	text-align: center;
	width: @size;
}

#villamhirek {
	margin-bottom: 20px;

	.bekuld {
		cursor: pointer;
	}
}

.szavazatok {
	float: left;
	background: @ikon_szavazatok 0 0 no-repeat;
	font-size: @baseFontSize * 1.2;
	font-style: italic;
	padding: 6px 0 0 12px;
	margin: 15px;
	width: 12px;
}

#benyujtott-palyamu {

	.infosav {
		//height: 200px;

		.display-tablecell {
			height: 200px;
		}

		.bg-kek {
			padding: 20px;
		}

		h3 {
			font-size: 20px;
			font-family: 'museo_sans300';
			font-style: italic;
		}

		dt {
			width: auto;
			margin-right: 10px;
		}

		dd {
			margin-left: 0;
		}
	}

	.tartalmisav {
//		margin-bottom: 20px;
	}

	.szavazok {
		display: table;
		width: 100%;
		height: 100%;

		div {
			display: table-cell;
			width: 100%;
			height: 100%;
			vertical-align: middle;
		}

		span {
			font-size: 25px;
			color: #3e6cb2;
			font-family: 'museo_sans700';
			display: block;
			width: 100%;
			text-align: center;
			text-transform: uppercase;
			margin: 0 0 18px;

			&.szavazatok-szama {
				background: url("@{images-url}szavazok_bg.png") no-repeat 44px center transparent;
				height: 110px;
				line-height: 90px;
				padding: 0 0 0 127px;
				font-family: 'museo_sans300';
				font-size: 60px;
				margin: 0;
			}
		}
	}

	h4 {
		font-size: 22px;
		font-family: 'museo_sans700';
		color: @kek2015;
	}
}

.logok {
	.logo {
		margin: 10px;
		display: block;
		float: left;
		position: relative;
		max-width: 150px;
		height: 100px;
		overflow: hidden;

		img {
			max-width: 150px;
		}

		.color {
			position: absolute;
			left: 0;
			top: 0;
			display: none;
		}
	}
}

.col {
	float: left;

	&.col1-3 {
		width: @colWidth;
	}

	&.col2-3 {
		width: @colWidth * 2 + @colMargin;
	}

	& + & {
		margin-left: @colMargin;
	}
}

.vezercikk {
	margin-bottom: 20px;
}

.socialmedia {

	&.list-inline>li {
		display: block;
		float: left;
	}

	li {

	}
	a {
		display: inline-block;
		width: 40px;
		height: 40px;
		margin: 2px;

		&#feliratkozas-link {
			height: auto;
			padding: 12px 0;
			width: auto;
		}

		&,
		svg {
			color: @kek2015;
			fill: @kek2015;
		}

		&:hover,
		&:hover svg {
			color: @vilagosKek2015;
			fill: @vilagosKek2015;
		}

		article &:hover {
//			background-image: @socialMediaKek;
		}

		article &{
//			background-image: @socialMediaKek;
		}
	}

	& + .visszadiv {
		padding-top: 24px;
	}

	footer & a {
//		background-image: @socialMediaIkonok;
		margin: 12px 3px 8px;
	}

	.youtube {
//		background-position: 0 0;
	}

	.twitter {
//		background-position: -25px 0;
	}

	.facebook {
//		background-position: -50px 0;
	}

	.pinterest {
//		background-position: -75px 0;
	}

	.googlemaps {
//		background-position: -100px 0;
	}

	.search {
//		background-position: -125px 0;
	}

	.rss {
//		background-position: -150px 0;
	}

	.skype {
//		background-position: -175px 0;
	}

	.email {
//		background-position: -200px 0;
	}
	.googleplus {
//		background-position: -175px 0;
	}
}

.vissza {
	background: @visszaNyil 0 0 no-repeat;
	padding-left: 25px;
	line-height: 19px;
	display: inline-block;

	&, &:visited, &:active {
		color: @betuSzin;
	}

	&:hover {
		color: @trabantKek;
		background-image: @visszaNyilKek;
	}
}

#hirek {
	a, a:visited, a:active {
		color: @betuSzin;
	}
}

article.hir,
article.szoveg {

	.datum {
		border-bottom: none;
		padding: 0;
	}

	.lead {
		font-style: italic;
		font-size: @baseFontSize * 1.6;
		line-height: 1.4em;
		margin: 0 0 35px;
		padding: 20px 0 10px;
		border-top: 1px solid @vilagosSzurke;
		color: #5292C7;

		p {
			margin: 0;
			text-align: justify;
		}
	}

	.szoveg {
		text-align: justify;
		margin-bottom: 15px;
		font-family: 'museo_sans300';
		font-size: 14px;
		padding-bottom: 15px;
		border-bottom: 1px solid @vilagosSzurke;

		img.fright {
			margin: 0 0 15px 15px;
		}
	}

	h1,h2,h3,h4,h5,h6 {
		line-height: 1.8em;
	}

	ul li {
		list-style: disc outside;
		margin-left: 20px;
	}

	ol li {
		list-style: decimal outside;
		margin-left: 20px;
	}

	table {
		margin: 0 auto;

		thead th {
			text-align: center;
			background: @majdnemFeher;
		}

		th, td {
			padding: 6px;
			border: 1px solid @vilagosSzurke;
		}
	}
}

.pagination {
	float: right;

	li {
		float: left;

		a {
			color: @betuSzin;
			display: inline-block;
			padding: 5px;
		}

		a:hover, &.active a {
			color: @trabantKek;
		}

		&.disabled {
			display: none;
		}
	}
}

.logok {
	background-color: #dddddd;

	h2 {
		font-size: 16px;
		color: #AFAFAF;
		font-family: 'museo_sans700';
	}

	div {
		background-repeat: no-repeat;
		background-position: 0 0;
		display: inline-block;
		cursor: pointer;

		a {
			display: block;
//			overflow: hidden;
			text-align: center;

			img {
				vertical-align: middle;
			}

			&:hover img {
				.opacity(100);
			}

			img {
				.opacity(20);
			}
		}

	}
}

#partnerek {
	@partnerWidth:	98px; //112px;
	@imgWidth:		80px; //88px;
	clear: both;

	div {
		width: @partnerWidth;
		height: @partnerWidth;
//		background-image: @bgPartnerlogo;
		background-size: @partnerWidth @partnerWidth;
		padding: 0 1px 13px 0;

		img {
			max-width: @imgWidth;
			max-height: @imgWidth;
		}
	}
}

#mediatamogatok {
	@mediaWidth:	80px;
	@imgWidth:		66px;

	div {
		width: @mediaWidth;
		height: @mediaWidth;
//		background-image: @bgMedialogo;
		padding: 0 3px 3px 0;

		a {
			width: @mediaWidth;
			height: @mediaWidth;
		}

		img {
			max-width: @imgWidth;
			max-height: @imgWidth;
		}
	}
}
#main{
	min-height: 400px;
	background: @bgPattern;
}

@media (max-width: 767px) {
	.piktogramok {
		display: none;
	}
	.header-fooldal{
		h1{
			font-size: 20px;
			text-transform: uppercase;
		}
		h2{
			font-size: 16px;
		}
	}
	.navbar-fixed-top{
		position: static;
	}
}

.table-responsive{
	overflow-x: auto;
}

.google-maps {
	position: relative;
	padding-bottom: 75%; // This is the aspect ratio
	height: 0;
	overflow: hidden;
}
.google-maps iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}

#hirlevel_feliratkozas{
	h2{
		text-transform: uppercase;
	}
	.alcim{
		font-style: italic;
		font-size: 16px;
	}
}

.seosiker-block{
	.btn.borderes:after{
		left: -8%;
	}
}

.nyitooldal{
	position:absolute;
	top:150px;
	right:50%;

	transform:translateX(-50vw);
	animation-name: mymove;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
	animation-delay: 2s;
	animation-fill-mode: forwards;
}

@keyframes mymove {
    0% {transform:translateX(-50vw);}
    100% {transform:translateX(-150px);}
}

@media (max-width: 767px) {
	@keyframes mymove {
	    0% {transform:translateX(-50vw);}
	    10% {transform:translateX(50%);}
		90% {transform:translateX(50%);}
		100% {transform:translateX(-50vw);}
	}
	.nyitooldal{
		animation-duration: 7s;
	}
	.hamarosan{
		font-size: 16px;
	}
}

.fooldal-hirbox {
	.hir-doboz, .hirhatter {
		height: auto;
	}

	.hirhatter .btn {
		position: static;
		margin-top: 1em;
	}
}
