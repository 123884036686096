#csempek {
	background: @bgPattern;
	line-height: @baseFontSize * 1.6;

	.container {
		padding-top: 80px;
		padding-bottom: 30px;
	}

	.sor {
		clear: both;
		margin-right: -1px;

		&:first-child {
			position: absolute;
			left: 0;
//			top: -42px;
		}
	}

	.h121 {
		height: 121px;
	}

	.h168 {
		height: 168px;
	}

	.p10 {
		padding: 10px;
	}


	.csempe h3 {
		font-size: 1.47em;
		line-height: 1.3;
	}

	.csempe {
		height: 100%;
		margin-right: 1px;
		margin-bottom: 1px;
		background-color: @trabantKek;
		float: left;
		color: @white;
		.box-sizing(border-box);
		padding: 10px;
		overflow: hidden;
		position: relative;

		&.w197 {
			width: 197px;
		}

		&.w233 {
			width: 233px;
		}

		&.w288 {
			width: 288px;
		}

		&.w329 {
			width: 329px;
		}

		&.w365 {
			width: 397px;
		}

		&.w467 {
			width: 467px;
		}

		&.w563 {
			width: 563px;
		}

		&.l233 {
			margin-left: 234px;
		}

		&.p0 {
			padding: 0;
		}

		h2 {
			font-size: @baseFontSize * 2;
			font-family: @calibri;
			line-height: 1.2em;
		}

		a, a:visited, a:active {
			color: @white;
		}

		a:hover {
			color: @sotetKek;
		}

		&.ajanlas {
			cursor: pointer;
		}

		&.ajanlas:hover {
			color: #0d6389;
		}

		&.statisztika {
			&>div {
				float: left;
				width: 110px;
			}

			span {
				display: block;
				text-align: center;
				margin: 0 10px;
			}

			.data {
				color: @vilagosKek;
				font-size: 30px;
				line-height: 1.2em;
				margin-top: 5px;
			}
			.szamok {
				margin-right: 10px;
			}
		}

		&.tavaly {
			h2 {
				font-size: @baseFontSize * 2.2;
				margin: 0;
			}

			.img {
				position: relative;
				display: block;

				&:after {
					content: '';
					display: block;
					width: 16px;
					height: 19px;
					position: absolute;
					right: 0;
					top: 128px;
					z-index: 50;
					background: @keknyilBalra;
				}
			}
		}

		&.facebook {
			background: @facebook @facebookF 50% 50% no-repeat;

			.like {
				position: absolute;
				left: 145px;
				top: 100px;
				font-size: @baseFontSize * 2.4;
			}
		}

		.menu a {
			font-weight: bold;
		}

		&.zsuritagok {
			overflow: visible;

			&:after {
				content: '';
				display: block;
				width: 19px;
				height: 16px;
				position: absolute;
				right: 30px;
				top: -16px;
				z-index: 50;
				background: @keknyilFel;
			}

			.menu {
				position: absolute;
				left: 10px;
				bottom: 10px;
			}
		}

		&.palyamuvek {
			background-image: url("@{images-url}bg_beerkezett_palyamuvek.png");
			background-position: right bottom;
			background-repeat: no-repeat;
			overflow: visible;

			h2 {
				width: 50%;
			}

			&:after {
				content: '';
				display: block;
				width: 19px;
				height: 16px;
				position: absolute;
				right: 30px;
				top: -16px;
				z-index: 50;
				background: @keknyilFel;
			}

			.menu {
				position: absolute;
				left: 10px;
				bottom: 10px;
			}
		}
	}
}

#csempek .dijatado_latszik>div {
	width: auto !important;
}

.uj {
	color: #ff0000;
	font-size: 13px;
//	top: -1em;
}

.iframe_bezaro{
	font-size: 24px;
	color: #fff;
	cursor:pointer;
}