footer {
	background-color: @sotetkek2015;
	min-height: @footerHeight;

	color: @vilagoskek2015;

	.footer-menu {
		li {
			line-height: @footerHeight;

			&,
			a {
				font-family: 'museo_sans300';
				font-size: 14px;
				color: @kek2015;

				&:hover {
					color: @vilagoskek2015;
				}
			}
		}
	}

	.also {
		position: absolute;
		bottom: 0;
		left: 0;
		width: @containerWidth;
		height: 50px;
		color: @white;
		line-height: 50px;

		a {
			&, &:active, &:visited {
				color: @white;
			}

			&:hover {
				color: lighten(@fomenupont, 10%);
			}
		}

		.alsomenu {
			margin-left: 65px;

			a {
				padding: 0 10px;
				border-left: 1px solid @white;

				&:first-child {
					border-left: 0 none;
				}
			}
		}

		.socialmedia {
			height: 50px;
			line-height: 0;
		}
	}
}

@media (max-width: 991px) {
	footer {
		padding-bottom: 30px;
	}
}