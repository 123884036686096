//
// Bootstrap Alerts
// --------------------------------------------------

@baseBorderRadius:		  4px;

@warningText:             #c09853;
@warningBackground:       #fcf8e3;
@warningBorder:           darken(spin(@warningBackground, -10), 3%);

@errorText:               #b94a48;
@errorBackground:         #f2dede;
@errorBorder:             darken(spin(@errorBackground, -10), 3%);

@successText:             #468847;
@successBackground:       #dff0d8;
@successBorder:           darken(spin(@successBackground, -10), 5%);

@infoText:                #3a87ad;
@infoBackground:          #d9edf7;
@infoBorder:              darken(spin(@infoBackground, -10), 7%);


// Base styles
// -------------------------

.alert {
  padding: 8px 35px 8px 14px;
  margin: @baseLineHeight 0;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  background-color: @warningBackground;
  border: 1px solid @warningBorder;
  .border-radius(@baseBorderRadius);
  position: relative;
}
.alert,
.alert h4 {
  // Specified for the h4 to prevent conflicts of changing @headingsColor
  color: @warningText;
}
.alert h4 {
  margin: 0;
}

// Adjust close link position
.alert .close {
  position: absolute;
  top: 0;
  right: 0;
  line-height: @baseLineHeight;
  color: @errorText;
  background: transparent;
}


// Alternate styles
// -------------------------

.alert-success {
  background-color: @successBackground;
  border-color: @successBorder;
  color: @successText;
}
.alert-success h4 {
  color: @successText;
}
.alert-danger,
.alert-error {
  background-color: @errorBackground;
  border-color: @errorBorder;
  color: @errorText;
}
.alert-danger h4,
.alert-error h4 {
  color: @errorText;
}
.alert-info {
  background-color: @infoBackground;
  border-color: @infoBorder;
  color: @infoText;
}
.alert-info h4 {
  color: @infoText;
}


// Block alerts
// -------------------------

.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}
