.ui-dialog {
	padding: 0;
	border: 0 none;

	.ui-widget-header {
		border: 0 none;
		padding: 1em 1.5em;

		.ui-dialog-title {
			color: @trabantKek;
			font-size: @baseFontSize * 3.6;
			font-family: @headerFont;
			font-weight: 100;
			line-height: 1.2em;
			letter-spacing: -1px;
		}

		.ui-dialog-subtitle {
			color: @betuSzin;
			font-family: @calibri;
			font-size: @baseFontSize * 2;
			font-weight: normal;
			line-height: 1.2em;
			margin-top: -5px;
			margin-bottom: 10px;
			letter-spacing: 0;
		}

		.ui-dialog-titlebar-close {
			border: 0 none;
			width: 33px;
			height: 33px;
			top: 20px;
			right: 10px;

			&:focus {
				outline: 0 none;
			}

			.ui-icon {
				width: 100%;
				height: 100%;
				background: @dialogBezar;
				margin: 0;
				left: 0;
				top: 0;
			}
		}
	}

	.ui-dialog-content {
		border: 0 none;
		padding: 1em 1.5em;
		height: auto;
	}
}