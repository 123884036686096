*{
	margin:0;
	padding:0;
}
.szkozep{
	text-align:center !important;
}
.szbal{
	text-align:left !important;
}
.szjobb{
	text-align:right !important;
}
.fleft{
	float:left !important;
}
.fright{
	float:right !important;
}
.alahuzva{
	text-decoration:underline !important;
}
.clear{
	clear:both !important;
	font-size:0px !important;
	height:0px !important;
	line-height:0px !important;
	float:none !important;
}
.clearboth{
	clear:both !important;
}
.hidden{
	display:none;
}
