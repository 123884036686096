// inlabel form

@labelColor:		@white;
@inputColor:		@white;
@inputHeight:		36px;
@inputLineHeight:	@inputHeight;
@inputTextIndent:	8px;
@inputPaddingTop:   5px;
@inputPaddingBottom:@inputPaddingTop;
@inputPadding:		@inputPaddingTop 0;
@inputWidth:		190px;

label {
	color: @betuSzin;
	display: block;
	font-weight: normal;
	margin-bottom: 5px;
}

input[type=text],
input[type=email],
input[type=password],
textarea {
	background: @inputBg !important;
	border: 0 none;
	color: @inputColor;
	display: block;
	font-family: @calibri;
	height: @inputHeight;
	line-height: @inputLineHeight;
	margin: 0;
	padding: @inputPadding;
	text-indent: @inputTextIndent;
	border-radius:0 !important;


	&:focus {
		background: @white;
		color: @betuSzin;
		outline: 1px solid @trabantKek;
		.box-shadow(none) !important;
	}
}

textarea {
	height: @inputHeight * 5 + @inputPaddingTop * 2;
	text-indent: 0;
	padding-left: @inputTextIndent;
	.box-sizing(border-box);
}

.inlabel {

	>div {
		position: relative;
		display: table;

		label {
			color: @labelColor;
			cursor: text;
			/*height: @inputHeight;*/
			line-height: @inputLineHeight;
			margin: 0;
			padding: @inputPadding;
			position: absolute;
			text-indent: @inputTextIndent;
		}
	}
}


// form > div,
// .inlabel > div {
// 	margin-bottom: 10px;
// }

form div {
	&.fele, &.negyede {
		.box-sizing(border-box);

		&.fleft {
			padding-right: 10px;
		}

		&.fright {
			padding-left: 10px;
		}
	}

	&.negyede {
		width: 25%;
	}

	&.fele {
		width: 50%;
	}

	&.egesz {
		.box-sizing(border-box);
		width: 100%;
	}
}

input[type=submit],
input[type=button],
input[type=reset],
.btn_anchor {
//	background: @szurke;
	border: 1px solid #3e6db2;
	// .box-sizing(content-box);
//	color: @white;
	display: block;
//	font-family: @calibri;
	// height: 26px;
	// line-height: 26px;
	margin: 0;
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: center;
	.border-radius(26px);
	font-size: 14px;

	&:hover {
//		background: @trabantKek;
	}
}

input[type=checkbox] {
	display: none;

	& + label {
		background: @checkboxDefault 0 0 no-repeat;
		color: @betuSzin;
		cursor: default;
		display: block;
		padding: 0 0 0 25px;
		line-height: @baseLineHeight;
		min-height: 19px;
		position: static;
		text-indent: 0;
		.userselect(none);
	}

	&:checked + label {
		background-image: @checkboxChecked;
	}
}

.btn_anchor {
	display: inline-block;
}


.captcha-div {
	padding-top: @baseLineHeight + @inputPaddingBottom;
}

::-webkit-input-placeholder {
	-webkit-text-fill-color: @text-color;
	color: @text-color;
}

::-moz-placeholder {
	color: @text-color;
}

:-moz-placeholder {
	color: @text-color;
}

:-ms-input-placeholder {
	color: @text-color;
}

::input-placeholder {
	color: @text-color;
}

:input-placeholder {
	color: @text-color;
}