#zsuri {

	@zsuritagMargin:	14px;

	.zsuri_lista {
		margin-right: 0 - @zsuritagMargin;
	}

	.zsuritag {

		// margin-right: @zsuritagMargin;
		// margin-bottom: @zsuritagMargin * 2;
		height: 280px;

		img {
			margin-bottom: @zsuritagMargin;

		}

		.nev, p {
			margin: 0;
		}
		.nev{
			margin-top: 10px;
		}

		.ceg {
			margin: @zsuritagMargin/2 0;
			color: @trabantKek;
			font-style: italic;
		}
	}
}

.zsuri-overlay{
	position: relative;
	max-width: 150px;
}

.zsuri-hover-image{
	display: none;
	position:absolute;
	width: 100%;
	height: 100%;
	border-radius: 90px;
	left: 0;
	right: 0;
	border: 10px solid white;
}
.zsuri-overlay:hover .zsuri-hover-image{
	background-color: rgba(16,29,129,0.7);
	display: block;
}